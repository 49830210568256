// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { AppShellComponentType } from '@priva/appshell';

export const environment = {
    debugState: false,
    offline: false,
    production: true,
    requireHttps: true,
    sourceMap: false,

    configuration: {
        name: 'Digital Services Support',
        uris: {
            grafanaSssDashboard:
                'https://priva-grafana-eagcfubmdnd6bqae.weu.grafana.azure.com/d/o1n8PQF4k/sss',
            installationAndMaintenance: 'https://installationandmaintenance.priva.com',
            webSupportApiService: '/digital-services-support-api',
        },
        analytics: {
            segment: {
                apiKey: '0cecWrRxvUarRnAh43EJN2MB9MyJyXP0',
            },
            appInsights: {
                instrumentationKey: '',
            },
        },
        components: {
            userInfo: {
                type: AppShellComponentType.UserInfo,
                behavior: 'service',
            },
            portalShortcut: {
                type: AppShellComponentType.PortalShortcut,
                behavior: 'link',
                pageUri: 'https://my.priva.com',
            },
            signoutShortcut: {
                pageUri: '/signout',
            },
        },
        authentication: {
            authConfig: {
                issuer: 'https://auth.priva.com',
                redirectUri: window.location.origin,
                postLogoutRedirectUri: window.location.origin,
                clientId: '069b78ca-69e7-47aa-846d-388138c54741',
                responseType: 'code',
                scope: 'openid priva.id priva.iam-web-support',
                showDebugInformation: false,
            },
        },
    },

    version: '0.1.0',
    buildDate: Date.now(),
};
